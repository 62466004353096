html, body, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.iframe-container {
  width: 100%;
  height: 100vh;
  display: flex;
}

iframe {
  flex: 1;
  border: none;
}